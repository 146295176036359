html {
    background: #1d1d1d;
    overflow: hidden;
    height: 100%;
    color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


@media only screen and (max-width: 350px) {
    html {
        zoom: 75%;
    }
}

@media only screen and (max-width: 410px) {
    html {
        zoom: 85%;
    }
}

body {
    height: 100%;
    overflow: auto;
    animation: fadein 1000ms;
}

.button_with_spacing {
    margin: 8px;
}

.app {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    justify-content: space-between;
}

.wrapper {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.remoteIndicatorContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    position: absolute;
}

.remoteIndicator {
    height: 20px;
    width: 20px;
    margin: 8px;
    border-radius: 10px;
}

.green {
    background-color: #46bd06;
}

.red {
    background-color: #bd0606;
}

.blink {
    animation: blink 2.5s linear infinite;
}

.blink_05 {
    animation: blink_05 2.5s linear infinite;
}

.master_container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease-in-out;
}

.slave_container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease-in-out;
}

.qr_camera_container {
    padding: 16px;
    border-radius: 4px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    animation: fadein 500ms;
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.header {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
    text-align: center;
    z-index: 1300;
}

input[type=text], select {
    padding: 8px 16px;
    display: inline-block;
    border: 0px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type=submit] {
    background: #2699FB;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    height: 48px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type=submit]:hover {
    background-color: rgba(42, 71, 156, 0.8);
}

input[type=submit]:active {
    background-color: rgba(42, 71, 156, 0.4);
}

input[type=submit]:disabled {
    background-color: rgba(99, 99, 99, 0.4);
}

.footer {
    display: flex;
    max-height: 120px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    transition: all ease-in-out 250ms;
    z-index: 1300000000;
}

.footer.collapsed {
    padding: 0;
}

.navbar_item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.qr_entry_form {
    margin: 16px;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.label {
    margin-left: 8px;
    color: white;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #79d471;
}

input:focus + .slider {
    box-shadow: 0 0 1px #79d471;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

@keyframes blink_05 {
    50% {
        opacity: 0.5;
    }
}

@keyframes slide-in-right {
    from {
        opacity: 0;
        transform: translateX(-100px) scale(0, 0);;
    }
    to {
        opacity: 1;
        transform: unset;
    }
}


@keyframes slide-in-left {
    from {
        opacity: 0;
        transform: translateX(100px) scale(0.5, 0.5);
    }
    to {
        opacity: 1;
        transform: unset;
    }
}

@keyframes slide-up {
    from {
        opacity: 0;
        transform: translateY(500px);
    }
    to {
        opacity: 1;
        transform: unset;
    }
}

@keyframes grow {
    from {
        opacity: 0;
        transform: scale(1, 1) rotate3d(1, 0, 0, 90deg);
    }
    to {
        opacity: 1;
        transform: unset;
    }

}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.sc {
    width: 100%;
    animation: fadein ease-in-out 500ms;
}

.sc div {
    text-align: center;
}

.sc img {
    height: calc(50vh - 100px);
    width: auto;
    object-fit: contain;
}

.slick-slide, .slick-slide * {
    outline: none !important;
}

.slick-slide {
    outline: none;
    transition: all 500ms ease-in-out;
    transform: scale3d(1, 1, 1);;
}

.slick-dots li:not(.slick-active) button:before {
    color: white !important;
}

.slick-dots li.slick-active button:before {
    color: white !important;
}

.slick-slide:not(.slick-center) {
    opacity: 0.5;
    transform: scale3d(0.8, 0.8, 0.8);
}

.stepper-container {
    width: 100%;
    display: flex;
    justify-content: center;
    transition: all ease-in-out 500ms;
}

.stepper-segment {
    background: #2699FB;
    height: 4px;
    width: 8vw;
    margin-top: 8px;
    margin-left: 8px;
    margin-right: 8px;
    transition: all ease-in-out 250ms;
}

.inactive {
    opacity: 0.2;
}

.header-buttons {
    display: flex;
    margin: 8px;
    justify-content: space-between;
    align-items: center;
    max-height: 50px;
    width: 100%;
}

.bbc-logo {
    height: 80px;
    animation: slide-in-right ease-in-out 500ms;
}

.lang-flag {
    width: 30px;
    animation: slide-in-left ease-in-out 500ms;
}

.header-title {
    animation: grow ease-in-out 500ms;
    margin-left: 8px;
    margin-right: 8px;
}

.drawer-container {
    height: 50vh;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 16px;
    left: 16px;
    right: 16px;
    animation: ease-in-out slide-up 500ms;
    z-index: 10;
}

.drawer {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 8px;
    position: relative;
}

.drawer-icon {
    margin-top: 28px;
    height: 75px;
}

.drawer-title {
    color: #000000;
    font-size: 14pt;
    font-weight: 600;
    padding: 8px;
    opacity: 0.72;
}

.drawer-text {
    color: #645F5F;
    font-size: 12pt;
    padding: 8px;
    opacity: 0.72;
}

.drawer-footer {
    bottom: 8px;
    left: 8px;
    right: 8px;
    padding: 8px;
    position: absolute;
}

.selected-bag-type-img {
    width: 90%;
}

.backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    transition: all ease-in-out 500ms;
}

.backdrop.blurred {
    visibility: visible;
}

.weight-indicator {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.weight-indicator p {
    font-weight: bold;
    font-size: 21pt;
}

.weight-indicator p.weight-value {
    font-size: 35px;
    font-weight: bold;
}

.loader-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

p {
    color: #cccccc;
}

.airline-logo {
    height: 50px;
    border: 1px solid white;
    border-radius: 25px;
    margin-bottom: 16px;
    animation: fadein 500ms forwards;
}

.rounded-outline {
    position: relative;
    background: white;
    color: rgba(0, 0, 0, 0.6);
    padding: 8px 16px;
    border-radius: 16px;
    height: 38px;
    width: 80vw;
    max-width: 380px;
    text-align: center;
    transition: all 250ms ease-in-out;
    animation: fadein 500ms forwards;
}

.rounded-outline img {
    position: absolute;
    left: 25px;
    top: 12px;
}

.circle-loader {

}

.loader,
.loader:before,
.loader:after {
    background: #ffffff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}

.loader {
    color: #ffffff;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}

.loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 1.5em;
}

@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

.bbc-logo-main {
    position: fixed;
    animation: grow 500ms;
    animation-delay: 750ms;
    opacity: 0;
    animation-fill-mode: forwards;
}

.loader-wrapper {
    animation: fadeout 200ms;
    animation-delay: 800ms;
    animation-fill-mode: forwards;
}

.launch-screen {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all ease-in-out 500ms;
}

.launch-screen-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
    animation: fadein, slide-up 250ms;
}

.finished {
    opacity: 0;
}
